import React from "react";

function Agende() {
  const whatsappNumber = "+5521986790765";
  const message = "Olá, gostaria de marcar uma consulta.";

  const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

  return (
    <section>
      <div className="container-fluid py-5 bg-light text-center" id="agenda">
        <div className="container py-5 text-center">
          <div className="row">
            <div className="col-12">
              <p className="agende-title"><b>Agende sua consulta</b></p>
              <p>com nossos médicos especializados em Ortopedia e Traumatologia!</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <a href={whatsappLink} className="btn btn-danger px-5 py-3" target="_blank" rel="noopener noreferrer">
                <b>AGENDAR AGORA!</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Agende;

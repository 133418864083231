import React from "react";
import { FiPhoneCall, FiClock, FiMapPin } from "react-icons/fi";

function InfoJob() {
    return ( 
        <div className="container mt-5 p-5 " id="contato">
            <div className="row justify-content-center text-center">
                <div className="col-md-4 my-3">
                    <FiPhoneCall size={50} color="#940110" />
                    <p className="text-dark mt-2"><strong>CONTATOS</strong></p>
                    <p><a className="no-underline text-dark" href="tel:2125686999">(21) 2567-8601</a> (21) 2567-9426 <br /> (21) 98679-0765 / (21) 97673-1460</p>
                </div>
                <div className="col-md-4 my-3">
                    <FiClock size={50} color="#940110" />
                    <p className="text-dark mt-2"><strong>HORÁRIO DE ATENDIMENTO</strong></p>
                    <p>Segunda a Sexta: Das 8h às 18h</p>
                    <p><strong>Não</strong> abrimos aos sábados, domingos e feriados</p>
                </div>
                <div className="col-md-4 my-3">
                    <FiMapPin size={50} color="#940110" />
                    <p className="text-dark mt-2"><strong>ONDE ESTAMOS</strong></p>
                    <p><strong>Rio de Janeiro - RJ</strong><br />
                        Rua Campos Sales, 148, Tijuca</p>
                </div>
            </div>
            <iframe
                title="Localização no Mapa"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3674.937988606713!2d-43.22318562175153!3d-22.91565798465968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x997e56a74c48f7%3A0x6146ae39ad529542!2sCl%C3%ADnica%20de%20Acidentados%20e%20Ortopedia!5e0!3m2!1spt-PT!2spt!4v1707230033098!5m2!1spt-PT!2spt"
                width="100%"
                height="350"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    )
}

export default InfoJob;

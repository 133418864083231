import React from "react";
import { Carousel } from 'react-bootstrap';

import alianz from '../../assets/allianz-3.jpg';
import bancocentral from '../../assets/bancocentral-8.png';
import brasil from '../../assets/brasilass-7.jpg'
import bnds from '../../assets/bndes-4.jpg';
import bradesco from '../../assets/bradesco-8.jpg';
import cac from '../../assets/cac-5.jpg';
import camperj from '../../assets/camperj-0.gif';
import cassi from '../../assets/cassi-8.jpg';
import cnen from '../../assets/cnen-5.jpg';
import embratel from '../../assets/embratel-1.jpg';
import fassincra from '../../assets/fassincra-5.gif';
import fioprev from '../../assets/fioprev-8.jpg';
import gama from '../../assets/gama-6.jpg';
import golden from '../../assets/golden-0.jpg';
import mediservice from '../../assets/mediservice-1.png';
import mutua from '../../assets/mutua-5.gif';
import petrobras from '../../assets/petrobras-1.jpg';
import segcanada from '../../assets/segcanada-0.jpg';
import unimed from '../../assets/unimed-8.jpg';
import urmes from '../../assets/urmes.jpg';


function Convenios() {
    return (
        <div className="container my-5" id="convenios">
            <div className="row">
                <div className="col-md-8">
                    <p className="pb-2 agende-title"><strong>Planos de Saúde Aceitos</strong></p>
                    <div className="bg-warning p-3 rounded">
                        <p>ATENÇÃO! Em decorrência da notificação de rescisão do credenciamento com a seguradora <strong>SulAmérica</strong>, lamentamos informar que a partir de 22/03/2024, não estaremos mais disponíveis para atender os beneficiários desta operadora. Agradecemos pela compreensão e pela oportunidade de tê-los atendido.</p>
                    </div>
                    
                    <p>A CAORT é dedicada a tornar o acesso aos serviços de saúde o mais conveniente possível para nossos pacientes. Com parcerias com mais de 15 planos de saúde, estamos comprometidos em oferecer uma variedade de opções de cobertura para atender às necessidades individuais de cada paciente.</p>

                    <p>Entendemos que a escolha do plano de saúde é uma decisão importante e pessoal. Por isso, trabalhamos com uma ampla rede de provedores de seguros para garantir que você possa receber o tratamento de que precisa, de acordo com suas preferências e necessidades.</p>

                    <div className="bg-info p-3 rounded">
                        <p>Aceitamos: Allianz, Banco Central do Brasil, BNDES, Bradesco, Brasil Assistência, CAC, Camperj, Cassi, CNEN, Embratel, Fassincra, FioPrev, Gama Saúde, Golden Cross, Mediservice, Mutua, Petrobrás, Seguro Card Canadá, Unimed e URMES.</p>
                    </div>


                    <p>Além disso, para aqueles que preferem o atendimento particular, a CAORT oferece a opção de pagamento direto, assegurando que você sempre tenha a oportunidade de receber atendimento médico de alta qualidade.</p>

                    <p>Nossa prioridade é proporcionar cuidados de saúde excepcionais e acessíveis a todos os nossos pacientes, independentemente da sua escolha de cobertura. Estamos aqui para atendê-lo e cuidar de sua saúde da melhor forma possível.</p>
                </div>
                <div className="col-md-4">
                    <div className="row mt-5">
                        <div className="col-md-4 col-sm-4 mb-4">
                            <img className="d-block mx-auto w-50 mb-4" src={alianz} alt="Allianz" />
                            <img className="d-block mx-auto w-50 mb-4" src={bancocentral} alt="Banco Central" />
                            <img className="d-block mx-auto w-50 mb-4" src={brasil} alt="Brasil Assistência" />
                            <img className="d-block mx-auto w-50 mb-4" src={bnds} alt="BNDS" />
                        </div>
                        <div className="col-md-4 col-sm-4 mb-4">
                            <img className="d-block mx-auto w-50 mb-4" src={bradesco} alt="Bradesco" />
                            <img className="d-block mx-auto w-50 mb-4" src={segcanada} alt="Seguro Canada" />
                            <img className="d-block mx-auto w-50 mb-4" src={gama} alt="Gama" />
                            <img className="d-block mx-auto w-50 mb-4" src={fioprev} alt="FioPrev" />
                        </div>
                        <div className="col-md-4 col-sm-4 mb-4">
                            <img className="d-block mx-auto w-50 mb-4" src={cac} alt="CAC" />
                            <img className="d-block mx-auto w-50 mb-4" src={camperj} alt="Camperj" />
                            <img className="d-block mx-auto w-50 mb-4" src={cassi} alt="CASSI" />
                            <img className="d-block mx-auto w-50 mb-4" src={cnen} alt="CNEN" />
                        </div>
                        <div className="col-md-4 col-sm-4 mb-4">
                            <img className="d-block mx-auto w-50 mb-4" src={petrobras} alt="Petrobrás" />
                            <img className="d-block mx-auto w-50 mb-4" src={urmes} alt="Urmes" />
                            <img className="d-block mx-auto w-50 mb-4" src={golden} alt="Golden Cross" />
                            <img className="d-block mx-auto w-50 mb-4" src={embratel} alt="Embratel" />
                        </div>
                        <div className="col-md-4 col-sm-4 mb-4">
                            <img className="d-block mx-auto w-50 mb-4" src={fassincra} alt="Fassincra" />
                            <img className="d-block mx-auto w-50 mb-4" src={mutua} alt="Mutua" />
                            <img className="d-block mx-auto w-50 mb-4" src={mediservice} alt="Mediservice" />
                            <img className="d-block mx-auto w-50 mb-4" src={unimed} alt="Unimed" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Convenios;

import React from "react";
import { IoLogoWhatsapp, IoLogoInstagram } from "react-icons/io";

function Footer() {
    const linkStyle = {
        color: 'white',
        textDecoration: 'none',
        transition: 'color 0.3s ease', // Adiciona uma transição suave de cor no hover
    };

    const linkHoverStyle = {
        color: '#FF0', // Substitua 'yourHoverColor' pela cor desejada no hover
    };

    return (
        <div className="container-fluid py-5 text-white" style={{ backgroundColor: '#940110' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 pb-3">
                        <h5><b>Clínica de Acidentados e Ortopedia</b></h5>
                        <p>Siga-nos nas redes sociais:</p>
                        <a href='https://wa.me/+5521986790765' target="_blank" rel="noopener noreferrer" style={linkStyle} aria-label="Ícone do WhatsApp">
                            <IoLogoWhatsapp size={40} className="mr-3" alt="Ícone do WhatsApp" />
                        </a>
                        <a href="https://www.instagram.com/caortortopedia/" target="_blank" style={linkStyle} aria-label="Ícone do Instagram">
                            <IoLogoInstagram size={40} alt="Ícone do Instagram" />
                        </a>
                    </div>
                    <div className="col-md-4 pb-3">
                        <h5><b>Links Úteis</b></h5>
                        <ul className="list-unstyled">
                            <li><a href="#servicos" style={linkStyle}>Serviços</a></li>
                            <li><a href="#medicos" style={linkStyle}>Equipe Médica</a></li>
                            <li><a href="#agenda" style={linkStyle}>Agende sua consulta</a></li>
                            <li><a href="#convenios" style={linkStyle}>Convênios</a></li>
                            <li><a href="#sobre" style={linkStyle}>Sobre Nós</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 pb-3">
                        <h5><b>Endereço</b></h5>
                        <p>Rua Campos Sales, 148 - Tijuca</p>
                        <p>Rio de Janeiro - RJ</p>
                        <p>(21) 2567-8601 / 2567-9426</p>
                        <p>(21) 97673-1460 / (21) 98679-0765</p>
                    </div>
                </div>
                <div className="text-center pt-5 pb-4">
                    <p>&copy; {new Date().getFullYear()} Todos os direitos reservados</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;

import React from "react";
import { IoChatbubbleEllipses } from "react-icons/io5";

function HelpIcon() {
  const whatsappNumber = "+5521976731460";
  const message = "Olá, preciso de mais informações.";

  const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
      <div className="help-icon">
        <div className="message-container">
          <strong>Fale conosco!</strong>
        </div>
        <IoChatbubbleEllipses size={70} color="#00FF00" />
      </div>
    </a>
  );
}

export default HelpIcon;

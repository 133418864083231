import React from "react";

import felipe from '../../assets/ea9eaa65-d671-4732-84ff-e2922ceaa4a0.webp';
import cleiton from '../../assets/e1abd2df-3d9d-4a2b-908f-49fd811ef5a6.jpg';
import bruno from '../../assets/9119341c-f7d6-4df9-aa24-1ad0d9b57e98.jpg';
import bottino from '../../assets/a493398c-d721-4d00-9c2a-967f845ef973.webp';
import silvio from '../../assets/2b780c1b-0c88-4dab-b99b-4b2101ac9f89.jpg';
import wanderley from '../../assets/c76f630b-6c3b-4b02-81f3-be0f3c5045dd-8.webp';
import marcelo from '../../assets/5aebe1ee-9c79-44b0-81c1-949a33a1a733.webp';
import thiago from '../../assets/48_1f90e835.jpg';
import igor from '../../assets/44_a052addf.jpg';
import bruna from '../../assets/32_71df5e13.jpg'
import noimage from '../../assets/generic-avatar-1.jpg';


function Medicos() {
    return (
        <div className="container-fluid" id="medicos" style={{ backgroundColor: '#940110' }}>
            <div className="container py-5">
                <h1 className="text-center" style={{ color: '#FFF' }}><strong>Conheça nossa equipe médica</strong></h1>
                <div className="row py-3">
                <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={bruna} className="rounded-circle w-50" alt="Dra. Bruna Canteri Delocco" />
                        <h2 className="pt-3">Dra. Bruna Delocco</h2>
                        <p className=" m-0">CRM 52.120866-7</p>
                        <p className="mt-4">Especialidade: <br /><strong>Oncologia Ortopédica, Ortopedia e Tratamento por ondas de choque</strong></p>
                        <p className="m-0">Atendimento: <br />6ª feira (8h às 11h) </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalBruna">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={bruno} className="rounded-circle w-50" alt="Dr. Bruno Coutinho" />
                        <h2 className="pt-3">Dr. Bruno Coutinho</h2>
                        <p className=" m-0">CRM 52.94547-1</p>
                        <p className="mt-4">Especialidade: <br /><strong>Joelho, Trauma e Ortopedia</strong></p>
                        <p className="m-0">Atendimento: <br />2ª feira (9h às 12h) <br />4ª feira (14h às 17h) <br /> 6ª feira (14h às 17h) </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalBruno" aria-label="Button ver currículo Dr. Bruno Coutinho">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 ml-2 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={cleiton} className="rounded-circle w-50" alt="Dr. Cleiton Naves" />
                        <h2 className="pt-3">Dr. Cleiton Naves</h2>
                        <p className=" m-0">CRM 52.92995-6</p>
                        <p className="mt-4">Especialidade: <br /><strong>Coluna Vertebral</strong></p>
                        <p className="m-0">Atendimento:<br />3ª feira (13h30 às 16h) <br /> 4ª feira (9h às 11h30) <br /> 5ª feira (13h30 às 16h) </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalCleiton">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={felipe} className="rounded-circle w-50" alt="Dr. Felipe Delocco" />
                        <h2 className="pt-3">Dr. Felipe Delocco</h2>
                        <p className=" m-0">CRM 52.75067-0</p>
                        <p className="mt-4">Especialidade: <br /><strong>Tornozelo e Pé</strong></p>
                        <p className="m-0">Atendimento:<br /> 6ª feira (8h às 11h20) </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalFelipe">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={igor} className="rounded-circle w-50" alt="Dr. Igor Vieira" />
                        <h2 className="pt-3">Dr. Igor Vieira</h2>
                        <p className=" m-0">CRM 52.100168-0</p>
                        <p className="mt-4">Especialidade: <br /><strong>Ombro e Cotovelo</strong></p>
                        <p className="m-0">Atendimento: <br />2ª feira (15h30 às 18h) <br />6ª feira (9h às 13h) </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalIgor">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={marcelo} className="rounded-circle w-50" alt="Dr. Marcelo Jorge" />
                        <h2 className="pt-3">Dr. Marcelo Jorge</h2>
                        <p className=" m-0">CRM 52.63742-4</p>
                        <p className="mt-4">Especialidade:<br /><strong>Cirurgia da Mão</strong></p>
                        <p className="m-0">Atendimento: <br />4ª feira (9h20 às 17h)  </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalMarcelo">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={silvio} className="rounded-circle w-50" alt="Dr. Silvio Antonio" />
                        <h2 className="pt-3">Dr. Silvio Antonio</h2>
                        <p className=" m-0">CRM 52.33905-0</p>
                        <p className="mt-4">Especialidade:<br /><strong>Ortopedia geral</strong></p>
                        <p className="m-0">Atendimento: <br />2ª feira (09h às 17h) <br />5ª feira (09h às 17h) </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalSilvio">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={thiago} className="rounded-circle w-50" alt="Dr. Thiago Alta Maurício Alvim" />
                        <h2 className="pt-3">Dr. Thiago Alvim</h2>
                        <p className=" m-0">CRM 52.101465-0</p>
                        <p className="mt-4">Especialidade:<br /><strong>Quadril</strong></p>
                        <p className="m-0">Atendimento: <br />2ª feira (13h às 17h) </p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalThiago">Ver currículo</button>
                    </div>

                    <div className="col-md-4 py-3 text-center mb-4 ml-4 border" style={{ backgroundColor: '#FFF', borderRadius: '10px' }}>
                        <img src={wanderley} className="rounded-circle w-50" alt="Dr. Wanderley Carvalho" />
                        <h2 className="pt-3">Dr. Wanderley Carvalho</h2>
                        <p className=" m-0">CRM 52.32213-8</p>
                        <p className="mt-4">Especialidade:<br /><strong>Ortopedia geral</strong></p>
                        <p className="m-0">Atendimento: <br />3ª feira (8h às 17h) <br />4ª feira (8h às 12h) <br /> 5ª feira (8h às 12h)</p>
                        <button className="btn btn-danger m-3" data-bs-toggle="modal" data-bs-target="#ModalWanderley">Ver currículo</button>
                    </div>

                    
                </div>
            </div>

            
                <div className="modal" id="ModalBruno">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                                <h1 className="modal-title"><strong>Dr. Bruno Parilha Coutinho</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                    
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <img src={bruno} className="w-100 rounded" />
                            </div>
                            <div className="col-md-8 mt-3">
                                <p className=" m-0">CRM 52.94547-1</p>
                                <p className="mt-2">Especialidade: <br /><strong>Joelho, Trauma e Ortopedia</strong></p>
                                <p className="mt-2">Atendimento: <br />2ª feira (9h às 12h) <br />4ª feira (14h às 17h) <br /> 6ª feira (14h às 17h) </p>
                            </div>
                            
                        </div>
                        <h2 className="mt-4">Formação e Especializações</h2>
                        <ul>
                            <li>Graduação em Medicina - Universidade Gama Filho</li>
                            <li>Residência Médica em Ortopedia e Traumatologia - Hospital Municipal Miguel Couto</li>
                            <li>Especialização em Cirurgia do Trauma Ortopédico - Hospital Municipal Miguel Couto</li>
                            <li>Especialização em Cirurgia do Joelho - Hospital Universitário Gaffree Guinle</li>
                            <li>Fellow International Visitor Harborview Medical Center - Seattle USA</li>
                            <li>Título de Especialista em Ortopedia e Traumatologia pelo MEC</li>
                            <li>Título de Especialista em Ortopedia e Traumatologia pela SBOT</li>
                            <li>Título de Especialista em Ortopedia e Traumatologia pela AMB</li>
                            <li>Membro Titular da Sociedade Brasileira de Ortopedia e Traumatologia</li>
                            <li>Membro AO Trauma - AO Trauma Latin America</li>
                            <li>Master AO de Trauma curta duração - 2019</li>
                            <li>Avançado AO de Trauma curta duração - 2018</li>
                            <li>Básico AO de Trauma curta duração - 2016</li>
                            <li>Responsável pela clínica do joelho da CAORT. Atuando na cirurgia e clínica do joelho e trauma</li>
                        </ul>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                    </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="ModalBruna">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                        <h1 className="modal-title"><strong>Dra. Bruna Canteri Delocco</strong></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <img src={bruna} className="w-100 rounded" alt="Dra. Bruna Canteri Delocco" aria-label="Dra. Bruna" />
                            </div>
                            <div className="col-md-8 mt-3">
                                <p className=" m-0">CRM 52.120866-7</p>
                                <p className="mt-2">Especialidade: <br /><strong>Oncologia Ortopédica, Ortopedia e Tratamento por ondas de choque</strong></p>
                                <p className="mt-2">Atendimento: <br />6ª feira (8h às 11h)</p>
                            </div>
                            
                        </div>
                        <h2 className="mt-4">Formação e Especializações</h2>
                        <ul>
                            <li>Médica pela Universidade Federal do Paraná (UFPR)</li>
                            <li>Ortopedista e Traumatologista pelo Hospital de Clínicas/UFPR - RQE 39102</li>
                            <li>Oncologista Ortopédica pelo INTO/RJ</li>
                            <li>Membro Titular da Sociedade Brasileira de Ortopedia e Traumatologia - TEOT 18690</li>
                            <li>Membro Titular da Sociedade Médica Brasileira de Tratamento por Ondas de Choque</li>
                        </ul>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                    </div>

                    </div>
                </div>
                </div>

                <div className="modal" id="ModalFelipe">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                                <h1 className="modal-title"><strong>Dr. Felipe Oliveira Delocco</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src={felipe} className="w-100 rounded" />
                                    </div>
                                    <div className="col-md-8 mt-3">
                                        <p className=" m-0">CRM 52.75067-0</p>
                                        <p className="mt-2">Especialidade: <br /><strong>Tornozelo e Pé</strong></p>
                                        <p className="mt-2">Atendimento: <br />6ª feira (8h às 11h20)</p>
                                    </div>
                                </div>
                                
                                <h2 className="mt-4">Formação e Especializações</h2>
                                <ul>
                                    <li>Universidade Federal do Rio de Janeiro - UFRJ
                                        <ul>
                                            <li>Curso de Medicina</li>
                                        </ul>
                                    </li>
                                    <li>Hospital de Força Aérea do Galeão - HFAG
                                        <ul>
                                            <li>Residência Médica em Ortopedia e Traumatologia</li>
                                        </ul>
                                    </li>
                                    <li>Universidade de São Paulo - HC - IOT - FMUSP
                                        <ul>
                                            <li>Prática Profissionalizante em Tornozelo e Pé (R4) 2007</li>
                                        </ul>
                                    </li>
                                    <li>Clinica Luganese - Lugano - Suiça
                                        <ul>
                                            <li>International AO Trauma/Foot and Ankle Fellowship 2008</li>
                                        </ul>
                                    </li>
                                    <li>Universidade da Força Aérea - UNIFA
                                        <ul>
                                            <li>Pós-Graduação em Medicina Aeroespacial em 2010</li>
                                        </ul>
                                    </li>
                                    <li>AOFAS - American Orthopaedic Foot and Ankle Society
                                        <ul>
                                            <li>Travelling Fellowship Program 2011</li>
                                        </ul>
                                    </li>
                                </ul>

                                <h2 className="mt-4">Títulos e Filiações</h2>
                                <ul>
                                    <li>Título de Especialista em Ortopedia e Traumatologia pelo MEC</li>
                                    <li>Titulo de Especialista em Ortopedia e Traumatologia pela SBOT</li>
                                    <li>Titulo de Especialista em Ortopedia e Traumatologia pela AMB</li>
                                    <li>Membro Titular da Sociedade Brasileira de Ortopedia e Traumatologia</li>
                                    <li>Membro Titular da Associação Brasileira de Medicina e Cirurgia do Tornozelo e Pé - ABTpé</li>
                                    <li>Active Member de "La Sociètè Internationale de Chirurgie Orthopédique et de Traumatologie" - SICOT</li>
                                    <li>Membro da "American Academy of Orthopaedic Surgeons" - AAOS</li>
                                    <li>Membro do Projeto Diretrizes em Ortopedia - SBOT/AMB</li>
                                    <li>Membro AO Alumni - AO Trauma Latin America</li>
                                    <li>Faculty convidado
                                        <ul>
                                            <li>Curso Avançado AO de Cirurgia do Tornozelo e Pé em 2008</li>
                                        </ul>
                                    </li>
                                    <li>Membro da "American Orthopaedic Foot and Ankle Society" - AOFAS</li>
                                    <li>Membro da CET - Comissão de Ensino e Treinamento da SBOT-RJ no ano de 2014, 2015, 2016, 2017, 2018, 2019 e 2020</li>
                                    <li>Diretor Regional da Associação Brasileira de Medicia e Cirurgia do Tornozelo e Pé - ABTPé no Rio de Janeiro nos anos de 2016 e 2017</li>
                                    <li>2º Tesoureiro da Associação Brasileira de Medicia e Cirurgia do Tornozelo e Pé - ABTPé para os bienios 2018/2019 e 2020/2021</li>
                                    <li>Médico do Comitê Olímpico Militar Brasileiro nos 7º Jogos Mundiais Militares - Wuhan - China 2019</li>
                                    <li>Autor de alguns capítulos de livros didáticos na área de ortopedia</li>
                                </ul>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal" id="ModalIgor">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                        <h1 className="modal-title"><strong>Dr. Igor Vieira</strong></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <img src={igor} className="w-100 rounded" />
                            </div>
                            <div className="col-md-8 mt-3">
                                <p className=" m-0">CRM 52.100168-0</p>
                                <p className="mt-2">Especialidade: <br /><strong>Ombro e Cotovelo</strong></p>
                                <p className="mt-2">Atendimento: <br />2ª feira (15h30 às 18h) <br />6ª feira (9h às 13h)</p>
                            </div>
                            
                        </div>
                        <h2 className="mt-4">Formação e Especializações</h2>
                        <ul>
                            <li>Ortopedista e Traumatologista - RQE 34452</li>
                            <li>Membro titular da sociedade de ortopedia e traumatologia</li>
                            <li>Membro titular da sociedade de cirurgiões de ombro e cotovelo</li>
                            <li>Membro do Departamento médico da seleção brasileira de vôlei - CBV</li>
                        </ul>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                    </div>

                    </div>
                </div>
                </div>

                <div className="modal" id="ModalMarcelo">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                        <h1 className="modal-title"><strong>Dr. Marcelo Jorge</strong></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <img src={marcelo} className="w-100 rounded" />
                            </div>
                            <div className="col-md-8 mt-3">
                                <p className=" m-0">CRM 52.63742-4</p>
                                <p className="mt-2">Especialidade: <br /><strong>Cirurgia da Mão</strong></p>
                                <p className="mt-2">Atendimento: <br />4ª feira (9h20 às 17h)</p>
                            </div>
                            
                        </div>
                        <h2 className="mt-4">Formação e Especializações</h2>
                        <ul>
                            <li>Formado em 1997 na Universidade Federal Fluminense - UFF.</li>
                            <li>Título de Especialista em Ortopedia e Traumatologia pela SBOT</li>
                            <li>Título de Especialista em Cirurgia da Mão - ASSOCIAÇÃO MEDICA BRASILEIRA- ASSOCIAÇÃO BRASILEIRA DE CIRURGIA DA MÃO</li>
                            <li>Especialização - Cirurgia da mão e Microcirurgia - Universidade do Estado do Rio de Janeiro - Hospital Universitário Pedro Ernesto (HUPE-UERJ)</li>
                            <li>Realizou diversos cursos de atualização em Ortopedia e Traumatologia na Sociedade Brasileira de Ortopedia e Traumatologia (SBOT).</li>
                            <li>Realizou diversos cursos de atualização e Conduta em Cirurgia da Mão - HUPE/UERJ</li>
                            <li>Palestrante com o tema "Fraturas de Escafóide"</li>
                            <li>Livros Publicados: 2010 Plexo Braquial- Editora Livros - Autor dos capítulos 17,18,20, e capítulo 25.</li>
                        </ul>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                    </div>

                    </div>
                </div>
                </div>

                <div className="modal" id="ModalSilvio">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                        <h1 className="modal-title"><strong>Dr. Silvio Antonio</strong></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <img src={silvio} className="w-100 rounded" />
                            </div>
                            <div className="col-md-8 mt-3">
                                <p className=" m-0">CRM 52.33905-0</p>
                                <p className="mt-2">Especialidade: <br /><strong>Ortopedia geral</strong></p>
                                <p className="mt-2">Atendimento: <br />2ª feira (09h às 17h) <br />5ª feira (09h às 17h)</p>
                            </div>
                            
                        </div>
                        <h2 className="mt-4">Formação e Especializações</h2>
                        <ul>
                            <li>Formado em 1979 na Universidade Estadual do Rio de Janeiro (UERJ).</li>
                            <li>Título de Especialista em Ortopedia e Traumatologia pela SBOT.</li>
                            <li>Co-Autor de livros didáticos na área de ortopedia.</li>
                            <li>Membro da Sociedade latino Americana de Ortopedia e Traumatologia - SLAOT.</li>
                            <li>Realizou diversos cursos de atualização em Ortopedia e Traumatologia na Sociedade Brasileira de Ortopedia e Traumatologia (SBOT).</li>
                        </ul>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                    </div>

                    </div>
                </div>
                </div>

                <div className="modal" id="ModalThiago">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                        <h1 className="modal-title"><strong>Dr. Thiago Alvim</strong></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <img src={thiago} className="w-100 rounded" />
                            </div>
                            <div className="col-md-8 mt-3">
                                <p className=" m-0">CRM 52.101465-0</p>
                                <p className="mt-2">Especialidade: <br /><strong>Quadril</strong></p>
                                <p className="mt-2">Atendimento: <br />2ª feira (13h às 17h)</p>
                            </div>
                            
                        </div>
                        <h2 className="mt-4">Formação e Especializações</h2>
                        <ul>
                            <li>Graduação em Medicina. Universidade Federal de Juiz de Fora, UFJF, Juiz De Fora, Brasil.</li>
                            <li>Especialização em Cirurgia do Quadril. Instituto Nacional de Traumatologia e Ortopedia, INTO, Rio De Janeiro, Brasil. Título: Cirurgia de Quadril.</li>
                            <li>Especialização - Residência médica. Hospital Municipal Miguel Couto, HMMC, Brasil. Título: Ortopedia e Traumatologia.</li>
                            <li>Título de Especialista em Ortopedia e Traumatologia pela SBOT.</li>
                            <li>Responsável pela clínica de quadril da CAORT. Atuando na cirurgia e clínica do quadril e trauma.</li>
                            <li>Formação complementar:</li>
                            <ul>
                                <li>Extensão universitária em Liga Acadêmica de Neurologia. Universidade Federal de Juiz de Fora, UFJF, Juiz De Fora, Brasil.</li>
                            </ul>
                        </ul>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                    </div>

                    </div>
                </div>
                </div>

                <div className="modal" id="ModalWanderley">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                        <h1 className="modal-title"><strong>Dr. Wanderley Carvalho</strong></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <img src={wanderley} className="w-100 rounded" />
                            </div>
                            <div className="col-md-8 mt-3">
                                <p className=" m-0">CRM 52.32213-8</p>
                                <p className="mt-2">Especialidade: <br /><strong>Ortopedia geral</strong></p>
                                <p className="mt-2">Atendimento: <br />3ª feira (8h às 17h) <br />4ª feira (8h às 12h) <br /> 5ª feira (8h às 12h)</p>
                            </div>
                            
                        </div>
                        <h2 className="mt-4">Formação e Especializações</h2>
                        <ul>
                            <li>Formado em 1978 na Universidade Estadual do Rio de Janeiro (UERJ).</li>
                            <li>Pós-Graduação em Medicina do Trabalho - Universidade Souza Marques.</li>
                            <li>Título de Especialista em Ortopedia e Traumatologia pela SBOT.</li>
                            <li>Título de Especialista em Medicina Física e Reabilitação - PUC.</li>
                            <li>Realizou diversos cursos de atualização em Ortopedia e Traumatologia na Sociedade Brasileira de Ortopedia e Traumatologia (SBOT).</li>
                            <li>Médico Auditor do INSS.</li>
                        </ul>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                    </div>

                    </div>
                </div>
                </div>

                <div className="modal" id="ModalCleiton">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: "#940110", color: "white" }}>
                                <h1 className="modal-title"><strong>Dr. Cleiton Dias Naves</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src={cleiton} className="w-100 rounded" alt="Foto de Dr. Cleiton Naves" />
                                    </div>
                                    <div className="col-md-8 mt-3">
                                        <p className=" m-0">CRM 52.92995-6</p>
                                        <p className="mt-4">Especialidade: <br /><strong>Coluna Vertebral</strong></p>
                                        <p className="m-0">Atendimento:<br />3ª feira (13h30 às 16h) <br /> 4ª feira (9h às 11h30) <br /> 5ª feira (13h30 às 16h) </p>
                                    </div>
                                </div>
                                <h2 className="mt-4">Formação e Especializações</h2>
                                <ul >
                                    <li >Pós-graduação Lato Sensu modalidade Residência Médica em Ortopedia no Instituto Nacional de Traumatologia e Ortopedia (INTO) (2012 - 2014);</li>
                                    <li>Formação complementar em Cirurgia da Coluna Vertebral pelo Centro de Doenças da Coluna Vertebral do Instituto Nacional de Ortopedia e Traumatologia Jamil Haddad (INTO) (2015-2016);</li>
                                    <li>Pós-graduação Stricto Sensu - Mestrado em Patologia do Tecido Musculoesquelético no INTO/UFRJ (2015-2019) - Título de Mestre em Ciências (MSc) obtido em fevereiro de 2019;</li>
                                    <li>Membro titular da Sociedade Brasileira de Ortopedia e Traumatologia (SBOT) desde 2015;</li>
                                    <li>Membro titular da Sociedade Brasileira de Coluna (SBC) desde 2017;</li>
                                    <li>Membro efetivo da "North American Spine Society (NASS) desde 2017;</li>
                                    <li>Fellow - ("observership") em correção de escoliose pediátrica no Children's Hospital of Atlanta - Estados Unidos da América (EUA) em junho de 2018;</li>
                                    <li>Fellow - (''observership") em técnicas modernas de correção de deformidade da coluna vertebral - Hôpital Robert Debre Paris - França em dezembro de 2018;</li>
                                    <li>Capacitação técnica - Curso de correção de alinhamento sagital na Université de Lyon - Lyon França em 2018;</li>
                                    <li>Fellow - (''observership") em técnicas modernas de correção de deformidade da coluna vertebral - Amiens Hôpital - França em 2018;</li>
                                    <li>Atua como cirurgião de coluna vertebral na Clínica OrtoRio - Ortopedia Especializada e na clínica CAORT - Clínica de Acidentados e Ortopedia.</li>
                                </ul>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
                            </div>
                        </div>
                    </div>
                </div>





        </div>
    );
}

export default Medicos;

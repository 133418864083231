import React from "react";
import logo from '../../assets/logo_caort.png';
import HelpIcon from "../HelpIcon/HelpIcon";

function Header() {
    return (
        <div className="fixed-top">
            <HelpIcon />
            <nav className="navbar navbar-expand-lg bg-light container-fluid">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src={logo} style={{ width: '80px' }} alt="Logo" />
                    </a>
                    <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="menu-icon">&#8801;</span> MENU
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="#top">Início</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#servicos">Serviços</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#medicos">Equipe Médica</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#agenda">Agende sua consulta</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#convenios">Convênios</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#sobre">Sobre Nós</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contato">Contato</a>
                            </li>
                            <p className="d-lg-none mt-2">(21) 98679-0765 / (21) 97673-1460</p>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;

import React from "react";
import image from '../../assets/3b1f25_d6aeb137f2ce4a4fb5af06fbba6a47b5.jpg';


function BannerTop() {
    return (
        <div className="banner-top-container" id="top">
            <img src={image} alt="Banner Top" className="banner-top-image" />

            <div className="banner-top-content">
                <p className=" mb-0" style={{color: '#940110'}}>CAORT </p>
                <h1 className="text-dark"><b>ORTOPEDIA E TRAUMATOLOGIA</b></h1>
                
            </div>
        </div>
    );
}

export default BannerTop;

import React from "react";
import { Carousel } from 'react-bootstrap';
import fachada from '../../assets/5144a1d9-0543-47a1-9a13-bbe5d9c24fe9.jpg';
import fachada2 from '../../assets/065dc259-887a-41d3-ba09-7c8f11524852.jpg';
import entrada from '../../assets/1.jpg';
import corredor from '../../assets/4.jpg';
import sala1 from '../../assets/5.jpg';
import sala2 from '../../assets/9.webp';
import proc1 from '../../assets/6.jpg';
import proc2 from '../../assets/7.jpg';

function Info() {
    const imageContainerStyle = {
        backgroundColor: '#940110', // Fundo vermelho na div da imagem
        borderRadius: '10px', // Bordas arredondadas
        overflow: 'hidden', // Esconde qualquer parte da imagem que saia do container
        maxHeight: '500px', // Defina uma altura máxima para as imagens
    };

    const imageStyle = {
        objectFit: 'cover', // Corta a imagem para manter o tamanho da altura igual
        width: '100%',
        height: '400px',
    };

    return (
        <div className="container-fluid " id="sobre" style={{ backgroundColor: '#940110' }}>
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Carousel>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={fachada}
                                        alt="Fachada da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={fachada2}
                                        alt="Fachada da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={entrada}
                                        alt="Entrada da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={corredor}
                                        alt="Corredor da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={sala1}
                                        alt="Sala 1 da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={sala2}
                                        alt="Sala 2 da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={proc1}
                                        alt="Procedimento 1 da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={imageContainerStyle}>
                                    <img
                                        className="d-block w-100 rounded"
                                        src={proc2}
                                        alt="Procedimento 2 da CAORT Ortopedia"
                                        style={imageStyle}
                                    />
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="col-md-6 py-2">
                        <div style={{ color: '#FFF' }}>
                            <p className="agende-title"><strong>Nossa História</strong></p>
                            <p>
                                Desde 1976, dedicamos nosso compromisso ao cuidado dos nossos pacientes.
                            </p>
                            <p>
                                A CAORT Ortopedia Ltda. foi estabelecida em 17 de setembro de 1976, no bairro da Tijuca, em conformidade com a legislação vigente.
                            </p>
                            <p>
                                Nosso foco principal é na área de Ortopedia e Traumatologia, com uma equipe de médicos altamente especializados em cirurgias de mão, quadril, joelho, ombro, cotovelo, tornozelo, pé e coluna. Além disso, realizamos diversos outros procedimentos médicos.
                            </p>
                            <p>
                                A CAORT mantém parcerias com mais de 15 convênios (planos de saúde), além de oferecer atendimento particular.
                            </p>
                            <p>
                                Priorizamos o agendamento de consultas, e nossa missão principal é proporcionar um atendimento médico excepcional.
                            </p>
                            <p>
                                Nossa equipe médica corporativa é composta exclusivamente por médicos-sócios e membros titulares da SBOT (Sociedade Brasileira de Ortopedia e Traumatologia).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;

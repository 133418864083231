import React from "react";

import service1 from '../../assets/service1.jpg'
import service2 from '../../assets/servicos2.jpg'

function Servicos() {
    return (
        <div className="container-fluid " id="servicos" style={{backgroundColor: '#940110', color: '#FFF'}}>
            <div className="container py-5">
            

            <div className="row">
                <div className="col-md-6 p-4">
                <h1 className="p-0" ><strong style={{color: '#FFF'}} >Serviços Prestados</strong></h1>
                    <p>Oferecemos uma ampla gama de serviços médicos, incluindo:</p>
                    <ul>
                        <li>Atendimento Ambulatorial</li>
                        <li>Ortopedia</li>
                        <li>Traumatologia</li>
                        <li>Cirurgia de mão</li>
                        <li>Cirurgia de Quadril</li>
                        <li>Cirurgia de Joelho</li>
                        <li>Cirurgia de Ombro</li>
                        <li>Cirurgia de Cotovelo</li>
                        <li>Cirurgia de Tornozelo</li>
                        <li>Cirurgia de Pé e Coluna</li>
                    </ul>
                    
                </div>
                <div className="col-md-6">
                    <img src={service1} className="w-100 rounded" alt="Service 1" />
                </div>
            </div>
            
            <div className="row mt-4">
                <div className="col-md-6">
                    <img src={service2} className="w-100 rounded" alt="Service 2" />
                </div>
                <div className="col-md-6 p-4">
                <h1 className="p-0" ><strong style={{color: '#FFF'}} >Procedimentos</strong></h1>
                    <p>Realizamos pequenos procedimentos ambulatoriais, tais como:</p>
                    <ul>
                        <li>Redução de fraturas</li>
                        <li>Imobilizações convencionais e não convencionais</li>
                        <li>Suturas</li>
                        <li>Radiografias (exclusivamente para nossos pacientes).</li>
                    </ul>
                    <p>Nossos serviços podem ser agendados com antecedência ou atendemos casos de urgência.</p>
                    <p>Estamos comprometidos em fornecer o melhor atendimento médico e tratamento para nossos pacientes.</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Servicos;

import React from "react";
import Header from "./components/Header/Header";
import styles from './css/styles.css'
import BannerTop from "./components/sections/BannerTop";
import Info from "./components/sections/Info";
import Footer from "./components/Footer/Footer";
import Agende from "./components/Agende/Agende";
import InfoJob from "./components/sections/InfoJob";
import Servicos from "./components/sections/Servicos";
import Medicos from "./components/sections/Medicos";
import Convenios from "./components/sections/Convenios";
import { Analytics } from "@vercel/analytics/react";


function App() {
  return (
    <>
   <Header />
   <BannerTop />
   <Servicos />
   <InfoJob />
   <Medicos />
   <Convenios />
   <Info />
   
   <Agende />
   <Footer />
   </>
  );
}

export default App;
